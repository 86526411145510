*{
  box-sizing:border-box;
  padding:0;
  margin:0;
  font-family: 'Encode Sans Expanded',
  sans-serif;
  font-size: 20px;
  background-color: transparent;
  

}